import React, { useState, useEffect, useRef } from 'react';
import { fetchLogin, postDevice, fetchSession } from '../services/login-api';
import { fetchManufacturers } from '../services/manufacturers-api';
import { fetchCategories } from '../services/categories-api';
import { fetchVersion, fetchHomolog } from '../services/system-api';
import { fetchCartItems } from '../services/cart-api';

import {
    deviceType,
    browserName,
    browserVersion,
    osName,
    osVersion
} from "react-device-detect";
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

const Login = (props) => {
    const [state, setState] = useState({
        usuario: "",
        senha: ""
    })

    const [loading, setLoading] = useState(false);

    const latestProps = useRef(props);

    useEffect(() => {
        // localStorage.clear();

        latestProps.current = props;
    }, []);

    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    const handleSubmitClick = (e) => {
        e.preventDefault();
        setLoading(true);
        const usuario = state.usuario;
        const senha = state.senha;

        const doLogin = () => {
            fetchLogin(usuario, senha, device)
                .then(result => {
                    if (result.data.Codigo === 200) {
                        const resultData = result.data.Data.Retorno;
                        const client = {
                            idCliente: resultData.idCliente,
                            idUsuario: resultData.idUsuario,
                            Login: resultData.Login,
                            Nome: resultData.Nome,
                            Email: resultData.Email,
                            ukPedido: resultData.ukPedido,
                            DataEmissao: resultData.DataEmissao,
                            NumeroItens: resultData.NumeroItens,
                            PedidoAlterado: resultData.PedidoAlterado,
                            LinkExpiracaoSessao: resultData.LinkExpiracaoSessao
                        }

                        localStorage.setItem('ampm_dados', JSON.stringify(client));
                        localStorage.setItem('ampm_pedido', client.ukPedido);
                        localStorage.setItem('ampm_token', resultData.TokenResposta);

                        Promise.all([
                            fetchManufacturers(resultData.TokenResposta),
                            fetchCategories(resultData.TokenResposta),
                            fetchVersion(resultData.TokenResposta),
                            fetchHomolog(resultData.TokenResposta),
                            fetchCartItems(resultData.TokenResposta)
                        ])
                            .then(resultFetch => {
                                localStorage.setItem('ampm_fornecedores', JSON.stringify(resultFetch[0].data.Data.Dados))
                                localStorage.setItem('ampm_categorias', JSON.stringify(resultFetch[1].data.Data.Dados))
                                localStorage.setItem('ampm_versao', JSON.stringify(resultFetch[2].data.Data))
                                localStorage.setItem('ampm_homolog', JSON.stringify(resultFetch[3].data.Data))
                                localStorage.setItem('ampm_carrinho', JSON.stringify(resultFetch[4].data.Data))

                                window.location.href = process.env.REACT_APP_BASE_URL
                            })
                    } else {
                        latestProps.current.history.push('/unauthorized');
                    };

                })
                .catch(error => {
                    latestProps.current.history.push('/unauthorized');
                })
        }

        const myCookie = Cookies.get('ampm_register');
        let device = myCookie;

        if (!myCookie) {
            const browser = browserName + ' ' + browserVersion + ' ' + deviceType;
            const so = osName + ' ' + osVersion;
            const serialNumber = uuidv4();
            const uniqueKey = uuidv4();

            postDevice(browser, so, uniqueKey, serialNumber)
                .then(result => {
                    if (result.data.Codigo !== 200) {
                        Cookies.remove('ampm_register');
                    }

                    Cookies.set('ampm_register', result.data.Data.Retorno, { expires: 3650 });
                    device = result.data.Data.Retorno;
                })
                .then(result => doLogin())
        } else {
            doLogin();
        }
    }


    // se está logado, direciona para Home
    if (localStorage.getItem('ampm_token')) {
        fetchSession()
            .then(result => {
                if (result.CodigoInterno != 4) {
                    window.location.href = process.env.REACT_APP_BASE_URL
                } else {
                    localStorage.clear();
                }
            })
        return null;
    }


    return (
        <section className="conteudo_interno container">
            <div>
                <h1 className="display-4">Login</h1>
                <form>
                    <div className="form-group text-left">
                        <label htmlFor="exampleInputEmail1">Usuário</label>
                        <input type="usuario"
                            className="form-control"
                            id="usuario"
                            aria-describedby="usuarioHelp"
                            placeholder="CNPJ"
                            value={state.usuario}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group text-left">
                        <label htmlFor="exampleInputPassword1">Senha</label>
                        <input type="password"
                            className="form-control"
                            id="senha"
                            placeholder="Código"
                            value={state.senha}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-check">
                    </div>
                    <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={handleSubmitClick}
                        disabled={loading}
                    >
                        {loading && <i className="fa fa-refresh fa-spin"></i>}
                        Acessar
                    </button>
                </form>
            </div>
        </section>
    )
}

export default Login;
import React, { useEffect, useState, createElement } from 'react';
// import { Link } from 'react-router-dom';
import FooterHomolog from './Homolog';

export default function Footer() {
    const [isHomolog, setIsHomolog] = useState(false);
    const [versionApi, setVersionApi] = useState('');

    useEffect(() => {
        const dadosHomolog = localStorage.getItem('ampm_homolog');
        setIsHomolog(dadosHomolog);

        const dadosVersion = localStorage.getItem('ampm_versao');
        setVersionApi(JSON.parse(dadosVersion));
    }, [])

    return (
        <footer className="footer">
            <div className="container">
                <div className="contato-container">
                    <nav className="nav-footer">
                        <ul className="nav-footer--lista list-unstyled">
                            <li className="nav-footer--lista_item">
                                {/* <Link to="/" className="nav-footer--lista_link">Ajuda e Suporte</Link> */}
                                {/* <Link to="/" className="nav-footer--lista_link">Cliente em Foco</Link> */}
                                {createElement('a', { href: process.env.REACT_APP_URL_CLIENTE_EM_FOCO, target: '_blank', className: 'nav-footer--lista_link' }, "Cliente em Foco")}
                            </li>
                            {/* <li className="nav-footer--lista_item"> */}
                                {/* <Link to="/" className="nav-footer--lista_link">Fale Conosco</Link> */}
                                {/* {createElement('a', { href: '/fale-conosco', className: 'nav-footer--lista_link' }, "Fale Conosco")} */}
                            {/* </li> */}
                        </ul>
                    </nav>
                    {/* <a href="mailto:ctampmsuprimentos@ipiranga.com.br" className="link-texto link-texto--footer">ctampmsuprimentos@ipiranga.com.br</a> */}
                </div>
                <div className="copyright">
                    <div className="copyright--container">
                        <p className="footer--texto">@{new Date().getFullYear()} am/pm - Todos os direitos reservados</p>
                        <div className="copyright--desenv">
                            <p className="footer--texto arial-bold mb-lg-0">Desenvolvido por Blink Systems Acessoria e Informática Ltda</p>
                            <p className="footer--texto arial-bold mb-0">Blink WebSales / Versão: {process.env.REACT_APP_VERSION} / API {versionApi}</p>
                        </div>
                    </div>
                </div>
            </div>
            <FooterHomolog 
                flag={isHomolog}
                version={process.env.REACT_APP_VERSION}
                versionApi={versionApi}
            />
        </footer>
    )
}